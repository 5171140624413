<template>
    <!--    <Link :link="route" class="dropdown-item text-truncate">
        {{ item.text }}
    </Link>-->
    <a class="dropdown-item text-truncate" href="#">
        {{ item.text }}
    </a>
</template>

<script>
import AutocompleteResultItem from '@/models/AutocompleteResultItem';
import autocompleteResultItemTypes from '@/models/autocompleteResultItemTypes';

export default {
    name: 'AutocompleteResultItem',
    props: {
        item: {
            type: [Object, AutocompleteResultItem],
            required: true
        }
    },
    computed: {
        route() {
            if (this.item.type === autocompleteResultItemTypes.hotel) {
                const { webname, id: hotelId } = this.item;
                return {
                    webnames: [webname],
                    hotelId
                };
            }
            // destination type might not have correspondent category page?
            //else return '#';
        }
    }
};
</script>
