<template>
    <div class="dropdown-menu results" ref="autocompleteResults">
        <h6 v-if="isEmpty" class="dropdown-header text-truncate font-weight-normal py-3 mt-0">
            {{ $t('SearchBar.autocomplete.noResults') }}
        </h6>
        <template v-else>
            <template v-if="cityAndRegions.length">
                <h6 class="dropdown-header text-truncate">
                    {{ $t('SearchBar.autocomplete.destination') }}
                </h6>
                <AutocompleteResultItem
                    v-for="(result, index) in cityAndRegions"
                    :key="'cityResult' + index"
                    :item="result"
                    @click.native="onClick(index)"
                    class="results__item"
                    :class="{ 'results__item--selected': selectedIndex === index }"
                    @mouseover.native="selectedIndex = index"
                />
            </template>
            <template v-if="hotels.length">
                <h6 class="dropdown-header text-truncate">
                    {{ $t('SearchBar.autocomplete.hotels') }}
                </h6>
                <AutocompleteResultItem
                    v-for="(result, index) in hotels"
                    :key="'hotelResult' + index"
                    :item="result"
                    @click.native="onClick(index + cityAndRegions.length)"
                    class="results__item"
                    :class="{ 'results__item--selected': selectedIndex === index + cityAndRegions.length }"
                    @mouseover.native="selectedIndex = index + cityAndRegions.length"
                />
            </template>
        </template>
    </div>
</template>

<script>
import AutocompleteResultItem from '@/components/search/AutocompleteResultItem';

export default {
    name: 'AutocompleteResults',
    components: {
        AutocompleteResultItem
    },
    data() {
        return {
            selectableItems: [],
            selectedIndex: -1
        };
    },
    props: {
        results: {
            type: Object,
            required: true,
            validate(value) {
                return Array.isArray(value.cityAndRegions) && Array.isArray(value.hotels);
            }
        },
        groupLimit: {
            type: Number,
            default: 5
        }
    },
    computed: {
        isEmpty() {
            return !this.cityAndRegions.length && !this.hotels.length;
        },
        selectable() {
            return [...this.cityAndRegions, ...this.hotels];
        },
        cityAndRegions() {
            const cityAndRegions = this.results.cityAndRegions || [];
            if (this.groupLimit) {
                return cityAndRegions.slice(0, this.groupLimit);
            }
            return cityAndRegions;
        },
        hotels() {
            const hotels = this.results.hotels || [];
            if (this.groupLimit) {
                return hotels.slice(0, this.groupLimit);
            }
            return hotels;
        }
    },
    methods: {
        select(direction = 1) {
            if (direction > 0) {
                this.selectedIndex = (this.selectedIndex + direction) % this.selectable.length;
            } else {
                this.selectedIndex =
                    (Math.max(this.selectedIndex, 0) + this.selectable.length + direction) % this.selectable.length;
            }

            this.$emit('select', this.selectable[this.selectedIndex].text);
        },
        selectDown() {
            this.select(1);
        },
        selectUp() {
            this.select(-1);
        },
        onClick(index) {
            this.selectedIndex = index;
            this.$emit('click', this.selectable[this.selectedIndex].text);
        }
    }
};
</script>

<style scoped lang="scss">
.dropdown-header {
    font-weight: bold;
    font-size: 16px;
    padding: 5px 10px;
}

.results__item {
    &--selected {
        background-color: var(--inputs-empty-color);
    }
}
</style>
