<template>
    <div class="dismissible" ref="dismissible">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'Dismissible',
    data() {
        let isTouchDevice;
        if (process.client) {
            isTouchDevice = 'ontouchstart' in document.documentElement;
        }

        return {
            observer: null,
            isTouchDevice
        };
    },
    props: {
        show: Boolean,
        exceptSelector: {
            type: String
        }
    },
    methods: {
        addBodyHandlers() {
            setTimeout(() => {
                document.body.addEventListener('click', this.closeDismissibleOutsideHandler);
                //https://www.quirksmode.org/blog/archives/2014/02/mouse_event_bub.html
                if (this.isTouchDevice) {
                    document.body.addEventListener('mouseover', this.emptyFunctionForIOSDelegationFix);
                }

                /* Array.prototype.forEach.call(document.body, element => {
                    element.addEventListener('click', test.bind(this));

                    if (this.isTouchDevice) {
                        element.addEventListener('mouseover', this.emptyFunctionForIOSDelegationFix);
                    }
                });*/
            }, 100);
        },
        removeBodyHandlers() {
            setTimeout(() => {
                document.body.removeEventListener('click', this.closeDismissibleOutsideHandler);

                if (this.isTouchDevice) {
                    document.body.removeEventListener('mouseover', this.emptyFunctionForIOSDelegationFix);
                }

                /*Array.prototype.forEach.call(document.body, element => {
                    element.removeEventListener('click', test.bind(this));
                    if (this.isTouchDevice) {
                        element.removeEventListener('mouseover', this.emptyFunctionForIOSDelegationFix);
                    }
                });*/
            }, 100);
        },
        emptyFunctionForIOSDelegationFix() {},
        closeDismissibleOutsideHandler(event) {
            const withinDismissible = (event.target.closest('.dismissible') || event.target) === this.$refs.dismissible;
            const exceptElement = document.querySelector(this.exceptSelector);
            const withinExcept = (event.target.closest(this.exceptSelector) || event.target) === exceptElement;

            if (withinDismissible || withinExcept) {
                event.stopImmediatePropagation();
                return event;
            }
            event.preventDefault();
            event.stopPropagation();
            this.close();
            return event;
        },
        close() {
            this.$emit('update:show', false);
            /*this.$nextTick(() => {
                this.removeBodyHandlers();
            });*/
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.observer = new MutationObserver(mutationsList => {
                for (var mutation of mutationsList) {
                    if (mutation.type == 'childList') {
                        if (mutation.target.childNodes.length && this.show) {
                            this.addBodyHandlers();
                        } else {
                            this.removeBodyHandlers();
                        }
                    }
                }
            });
            this.$refs.dismissible && this.observer.observe(this.$refs.dismissible, { childList: true });
        });
    },
    beforeDestroy() {
        this.observer && this.observer.disconnect();
        this.removeBodyHandlers();
    }
};
</script>
