import autocompleteResultItemTypes from '@/models/autocompleteResultItemTypes';

export default class AutocompleteResultItem {
    /**
     * @constructor
     * @param {Object} item
     * @param {Number} [item.id]
     * @param {String} item.text
     * @param {autocompleteResultItemTypes} item.type
     * @param {String} item.webname
     */
    constructor({ id, text, type, webname }) {
        this.id = id;
        this.text = text;
        this.type = type;
        this.webname = webname;
    }

    get hasHotelType() {
        return this.type === autocompleteResultItemTypes.hotel;
    }
}
