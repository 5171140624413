<template>
    <div class="autocomplete" :class="{ loading, ready: !loading }" :title="term">
        <div class="input-wrapper" :class="{ active: term }">
            <span class="prefix">
                <slot>
                    <IconSvg filepath="search/pinpoint.svg" />
                </slot>
            </span>

            <input
                type="text"
                @input="search"
                :placeholder="placeholder"
                class="autocomplete__input input-gray"
                :class="{ 'text-truncate': clearable, 'pr-3': clearable }"
                :aria-label="placeholder"
                @keydown.up.prevent="selectUp"
                @keydown.down.prevent="selectDown"
                @keydown.esc="blur"
                @keydown.enter.prevent="submitTerm"
                @click.stop="onFocus"
                @focusin="onFocus"
                @focusout="blur"
                :value="value"
                ref="input"
            />

            <button v-if="clearable && value" class="clear-btn" @click="clear">
                <IconSvg filepath="close.svg" />
            </button>

            <slot name="append"></slot>
        </div>

        <Dismissible :show="opened" @update:show="dismiss">
            <AutocompleteResults
                v-if="opened"
                ref="results"
                :groupLimit="0"
                :results="results"
                @click="clickResult"
                @select="selectResult"
            />
        </Dismissible>
    </div>
</template>

<script>
import Dismissible from '@/components/Dismissible';
import AutocompleteResultItem from '@/models/AutocompleteResultItem';
import AutocompleteResults from '@/components/search/AutocompleteResults';

export default {
    name: 'Autocomplete',
    components: {
        AutocompleteResults,
        Dismissible
    },
    data() {
        const $t = this.$t;
        return {
            term: '',
            selected: null,
            results: {},
            receivedSuggestions: false,
            loading: false,
            opened: false,
            timeout: null
        };
    },
    props: {
        minLength: {
            type: Number,
            default: 3
        },
        placeholder: String,
        delay: {
            type: Number,
            default: 500
        },
        value: String,
        clearable: Boolean,
        submitOnDesktop: Boolean
    },
    methods: {
        search(event) {
            const term = event.target.value;
            this.updateValue(term);

            clearTimeout(this.timeout);

            this.timeout = setTimeout(async () => {
                const trimmed = term.trim();
                //Doing search
                if (trimmed.length >= this.minLength) {
                    this.loading = true;

                    try {
                        const { data } = await this.$axios.get(`/shop/autocomplete?q=${trimmed}`);
                        this.results = data;
                        this.receivedSuggestions = true;
                        this.opened = true;
                    } finally {
                        this.loading = false;
                    }
                }
                //Cleaning
                else if (trimmed.length === 0) {
                    /*this.opened = false;
                    this.$emit('change', {});*/
                }
            }, this.delay);
        },
        dismiss(opened) {
            this.opened = opened;
        },
        selectDown() {
            if (this.$refs.results) {
                this.$refs.results.select(1);
            }
        },
        selectUp() {
            if (this.$refs.results) {
                this.$refs.results.select(-1);
            }
        },
        submitTerm() {
            this.opened = false;
            this.blur();
            this.$nextTick(() => {
                this.$router.push({
                    name:
                        this.$route.name === 'searchResult' || this.$route.name === 'mapSearch'
                            ? this.$route.name
                            : 'searchResult',
                    query: {
                        ...this.$route.query,
                        q: this.$refs.input.value //need to take input el value instead, as it is up-to-date
                    }
                });
            });
        },
        updateValue(value) {
            this.$emit('update:value', value);
        },
        clear() {
            this.updateValue('');
            //this.$emit('change', new AutocompleteResultItem({ text: this.term }));
            this.$refs.input.focus();
        },
        onFocus() {
            if (this.receivedSuggestions) {
                this.opened = true;
            }
        },
        blur(e) {
            this.$refs.input && this.$refs.input.blur();
            return e;
        },
        clickResult(value) {
            this.updateValue(value);
            this.dismiss();

            // mobile devices do not display submit button
            if (this.$root.isMobile || this.submitOnDesktop) {
                this.submitTerm();
            }
        },
        selectResult(value) {
            this.updateValue(value);
        }
    },
    activated() {
        this.$refs.input.focus();
    },
    watch: {
        selected(value) {
            this.term = value ? value.text : '';
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/mixins';

.autocomplete {
    position: relative;

    .input-wrapper {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        align-items: center;

        input {
            border: none;
            outline: none;
            flex: 1;
            padding-left: 0;
            padding-right: 0;
        }

        &.active {
            .prefix {
                color: var(--primary) !important;
            }
        }

        .clear-btn {
            color: variables.$muted;
            padding: 0;
            outline: none;
            border: none;
            background: transparent;
            font-size: 0;
            position: absolute;
            right: 0;

            .icon-close {
                font-size: 12px !important;
            }
        }
    }

    .results {
        border: 1px solid var(--border-color);
        background: var(--white);
        display: block;
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        list-style: none;
        padding: 0;
        outline: none;
        cursor: default;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        overflow-y: auto;
        max-height: 400px;
        min-width: 300px;

        @include mixins.mobile-only {
            max-height: 60vh;
            max-width: 90vw;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &::after {
        border-bottom: 2px solid var(--primary);
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        transition: width 0.3s ease-in-out;
        width: 0;
        max-width: 90%;
        opacity: 1;
    }

    &.loading::after {
        animation: animateAutocompleteLoading 0.3s ease-in-out;
        animation-fill-mode: forwards;
    }

    &.ready::after {
        opacity: 1;
        width: 100%;
        max-width: 100%;
        animation: animateAutocompleteReady 0.3s ease-in-out;
        animation-fill-mode: forwards;
    }

    @keyframes animateAutocompleteLoading {
        0% {
            width: 0;
            max-width: 90%;
        }
        50% {
            width: 80%;
        }
        100% {
            width: 90%;
            max-width: 90%;
        }
    }

    @keyframes animateAutocompleteReady {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
}
</style>
